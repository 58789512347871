import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useFetch } from 'hooks'
import { signIn, SignInParams } from 'services/api'

import { EmailAndPasswordResult } from './useTwoFactorAuthentication'

type EmailAndPasswordFormValues = {
  login: string
  password: string
}

const EMAIL_AND_PASSWORD_SCHEMA = yup.object().shape({
  login: yup
    .string()
    .required('Email is a required field')
    .email('Email must be a valid email'),
  password: yup.string().required('Password is a required field'),
})

function useEmailAndPasswordSubmit() {
  const { fetch, isFetching, isFetchSuccessful, data, error, reset } = useFetch(
    async (params: SignInParams) => {
      return await signIn(params)
    },
  )

  return {
    submit: fetch,
    isSubmitting: isFetching,
    isSubmitSuccessful: isFetchSuccessful,
    error,
    data,
    reset,
  }
}

function useEmailAndPasswordForm() {
  return useForm<EmailAndPasswordFormValues>({
    resolver: yupResolver(EMAIL_AND_PASSWORD_SCHEMA),
    mode: 'onChange',
    defaultValues: { login: '', password: '' },
  })
}

export default function useEmailAndPasswordSignIn(
  setResult: (result: EmailAndPasswordResult) => void,
) {
  const submit = useEmailAndPasswordSubmit()
  const form = useEmailAndPasswordForm()

  useEffect(() => {
    if (form.formState.isValid) {
      submit.reset()
    }
  }, [form.formState.isValid])

  useEffect(() => {
    const { login, password } = form.getValues()

    if (!submit.isSubmitting && submit.error) {
      form.reset({ password: '', login })
      form.setError('password', {
        type: 'validation',
        message: submit.error.error_description,
      })
    } else if (submit.isSubmitSuccessful && !submit.isSubmitting) {
      setResult({ ...submit.data, login, password })
    }
  }, [submit.error, submit.isSubmitting, submit.isSubmitSuccessful])

  return {
    submit,
    form,
    handleSubmit: form.handleSubmit(submit.submit),
  }
}
