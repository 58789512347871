import { useEffect, useState } from 'react'

import { getAidboxResources, Observation } from 'services/api'
import { useFetch } from 'hooks'
import { formatNumber, getDateOrTime } from 'utils'
import { HealthDataItemValues } from '../types'
import useLocalStorage from './useLocalStorage'

const STORAGE_KEY = 'jevittyWeightData'

const { storeData, restoreData, clearData } = useLocalStorage(STORAGE_KEY)

export default function useWeight(patientId: string): HealthDataItemValues {
  const [weight, setWeight] = useState<number | null>(restoreData().value)
  const [date, setDate] = useState<string | null>(restoreData().date)

  const { fetch, data, isFetched, isFetching } = useFetch(
    async () =>
      await getAidboxResources<Observation>('Observation', {
        patient: patientId,
        code: 'HKQuantityTypeIdentifierBodyMass',
        _sort: '-.effective.Period.end',
        _count: 1,
        _elements: 'value.Quantity.value,effective.Period.start',
      }),
  )

  useEffect(() => {
    if (data && data[0]) {
      setWeight(data[0].value.Quantity.value / 1000.0)
      setDate(data[0].effective.Period.start)
    } else if (isFetched) {
      setWeight(null)
      setDate(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (weight !== null) {
      storeData(weight, date)
    }
  }, [weight])

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()

    const interval = setInterval(() => {
      if (patientId) fetch()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const displayValue = weight ? `${formatNumber(weight)} kg` : 'N/A'

  return {
    value: weight,
    displayValue,
    date: getDateOrTime(date),
    goalIsReached: true,
  }
}
