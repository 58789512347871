import { useEffect, useState } from 'react'

import { getAidboxResources, Observation } from 'services/api'
import { useFetch } from 'hooks'
import { formatNumber, getDateOrTime } from 'utils'
import { HealthDataItemValues } from '../types'
import {
  BODY_FAT_FEMALE_GOAL_MIN,
  BODY_FAT_FEMALE_GOAL_MAX,
  BODY_FAT_MALE_GOAL_MIN,
  BODY_FAT_MALE_GOAL_MAX,
} from 'settings'
import useLocalStorage from './useLocalStorage'

const STORAGE_KEY = 'jevittyBodyFatData'

const { storeData, restoreData, clearData } = useLocalStorage(STORAGE_KEY)

export default function useBodyFat(
  patientId: string,
  gender: string,
): HealthDataItemValues {
  const [bodyFat, setBodyFat] = useState<number | null>(restoreData().value)
  const [date, setDate] = useState<string | null>(restoreData().date)

  const isFemale = gender === 'Female'

  const { fetch, data, isFetched, isFetching } = useFetch(
    async () =>
      await getAidboxResources<Observation>('Observation', {
        patient: patientId,
        code: 'HKQuantityTypeIdentifierBodyFatPercentage',
        _sort: '-.effective.Period.end',
        _count: 1,
        _elements: 'value.Quantity.value,effective.Period.start',
      }),
  )

  useEffect(() => {
    if (data && data[0]) {
      setBodyFat(data[0].value.Quantity.value)
      setDate(data[0].effective.Period.start)
    } else if (isFetched) {
      setBodyFat(null)
      setDate(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (bodyFat !== null) {
      storeData(bodyFat, date)
    }
  }, [bodyFat])

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()

    const interval = setInterval(() => {
      if (patientId) fetch()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const displayValue = bodyFat ? `${formatNumber(bodyFat)}%` : 'N/A'
  const displayGoal = isFemale
    ? `${BODY_FAT_FEMALE_GOAL_MIN}-${BODY_FAT_FEMALE_GOAL_MAX}%`
    : `${BODY_FAT_MALE_GOAL_MIN}-${BODY_FAT_MALE_GOAL_MAX}%`
  const goalIsReached = isFemale
    ? bodyFat >= BODY_FAT_FEMALE_GOAL_MIN && bodyFat <= BODY_FAT_FEMALE_GOAL_MAX
    : bodyFat >= BODY_FAT_MALE_GOAL_MIN && bodyFat <= BODY_FAT_MALE_GOAL_MAX

  return {
    value: bodyFat,
    displayValue,
    goal: isFemale ? BODY_FAT_FEMALE_GOAL_MIN : BODY_FAT_MALE_GOAL_MIN,
    displayGoal,
    goalIsReached,
    date: getDateOrTime(date),
  }
}
