import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useFetch, useCountdownTimer } from 'hooks'
import { confirmSmsCode, signIn } from 'services/api'
import { RESEND_DELAY } from 'settings'

type SmsConfirmationFormValues = {
  code: string
}

type SmsConfirmationProps = {
  login: string
  password: string
  phoneConfirmationId: string
  setToken: (token: string) => void
}

const SMS_CONFIRMATION_SCHEMA = yup.object().shape({
  code: yup.string().required(),
})

function useSmsConfirmationForm() {
  return useForm<SmsConfirmationFormValues>({
    resolver: yupResolver(SMS_CONFIRMATION_SCHEMA),
    mode: 'onChange',
    defaultValues: { code: '' },
  })
}

function useSmsConfirmationSubmit({
  phoneConfirmationId,
}: {
  phoneConfirmationId: string
}) {
  const {
    fetch,
    data,
    error,
    reset,
    isFetching: isSubmitting,
    isFetchSuccessful: isSubmitSuccessful,
  } = useFetch(confirmSmsCode)

  const submit = useCallback(
    ({ code }: SmsConfirmationFormValues) => {
      fetch({ phoneConfirmationId, code })
    },
    [phoneConfirmationId],
  )

  return {
    submit,
    reset,
    isSubmitting,
    isSubmitSuccessful,
    data,
    error,
  }
}

function useCodeResend({
  login,
  password,
  setPhoneConfirmationId,
}: {
  login: string
  password: string
  setPhoneConfirmationId: (id: string) => void
}) {
  const [resendCountdown, resetResendCountdown] =
    useCountdownTimer(RESEND_DELAY)
  const {
    fetch: resend,
    data: resendData,
    isFetching: isResending,
  } = useFetch(async () => {
    return await signIn({ login, password })
  })

  useEffect(() => {
    if (resendData) {
      setPhoneConfirmationId(resendData.phoneConfirmationId)
      resetResendCountdown()
    }
  }, [resendData])

  return {
    resendCountdown,
    resetResendCountdown,
    resend,
    isResending,
  }
}

export default function useSmsConfirmation({
  login,
  password,
  phoneConfirmationId: initPhoneConfirmationId,
  setToken,
}: SmsConfirmationProps) {
  const [phoneConfirmationId, setPhoneConfirmationId] = useState(
    initPhoneConfirmationId,
  )

  const submit = useSmsConfirmationSubmit({ phoneConfirmationId })
  const form = useSmsConfirmationForm()
  const resend = useCodeResend({ login, password, setPhoneConfirmationId })

  useEffect(() => {
    if (form.formState.isValid) {
      submit.reset()
    }
  }, [form.formState.isValid])

  useEffect(() => {
    if (submit.isSubmitSuccessful && !submit.isSubmitting) {
      setToken(submit.data.token.access_token)
    } else if (submit.error) {
      form.reset()
      form.setError('code', {
        type: 'manual',
        message: submit.error.error_description,
      })
    }
  }, [submit.error, submit.isSubmitting, submit.isSubmitSuccessful])

  return {
    submit,
    form,
    handleSubmit: form.handleSubmit(submit.submit),
    resend,
  }
}
