import { useEffect, useState } from 'react'

import { getAidboxResources, Observation } from 'services/api'
import { useFetch } from 'hooks'
import { HealthDataItemValues } from '../types'
import useLocalStorage from './useLocalStorage'
import { getDateOrTime } from 'utils'

const STORAGE_KEY = 'jevittyRestingHeartRateData'

const { storeData, restoreData, clearData } = useLocalStorage(STORAGE_KEY)

export default function useRestingHeartRate(
  patientId: string,
): HealthDataItemValues {
  const [restingHeartRate, setRestingHeartRate] = useState<number | null>(
    restoreData().value,
  )
  const [date, setDate] = useState<string | null>(restoreData().date)

  const { fetch, data, isFetched, isFetching } = useFetch(
    async () =>
      await getAidboxResources<Observation>('Observation', {
        patient: patientId,
        code: 'HKQuantityTypeIdentifierRestingHeartRate',
        _sort: '-.effective.Period.end',
        _count: 1,
        _elements: 'value.Quantity.value,effective.Period.start',
      }),
  )

  useEffect(() => {
    if (data && data[0]) {
      setRestingHeartRate(data[0].value.Quantity.value)
      setDate(data[0].effective.Period.start)
    } else if (isFetched) {
      setRestingHeartRate(null)
      setDate(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (restingHeartRate !== null) {
      storeData(restingHeartRate, date)
    }
  }, [restingHeartRate])

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()

    const interval = setInterval(() => {
      if (patientId) fetch()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  return {
    value: restingHeartRate,
    displayValue: restingHeartRate ? `${restingHeartRate} bpm` : 'N/A',
    date: getDateOrTime(date),
    goalIsReached: true,
  }
}
