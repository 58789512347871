import { useEffect, useState } from 'react'

import { getAidboxResources, Observation } from 'services/api'
import { useFetch } from 'hooks'
import { formatNumber, getDateOrTime } from 'utils'
import { HealthDataItemValues } from '../types'
import { VO2_MAX_FEMALE_GOAL, VO2_MAX_MALE_GOAL } from 'settings'
import useLocalStorage from './useLocalStorage'

const STORAGE_KEY = 'jevittyVO2maxData'

const { storeData, restoreData, clearData } = useLocalStorage(STORAGE_KEY)

export default function useVo2Max(
  patientId: string,
  gender: string,
): HealthDataItemValues {
  const [vo2max, setVo2max] = useState<number | null>(restoreData().value)
  const [date, setDate] = useState<string | null>(restoreData().date)

  const isFemale = gender === 'Female'

  const { fetch, data, isFetching, isFetched } = useFetch(
    async () =>
      await getAidboxResources<Observation>('Observation', {
        patient: patientId,
        code: 'HKQuantityTypeIdentifierVO2Max',
        _sort: '-.effective.Period.end',
        _count: 1,
        _elements: 'value.Quantity.value,effective.Period.start',
      }),
  )

  useEffect(() => {
    if (data && data[0]) {
      setVo2max(data[0].value.Quantity.value)
      setDate(data[0].effective.Period.start)
    } else if (isFetched) {
      setVo2max(null)
      setDate(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (vo2max !== null) {
      storeData(vo2max, date)
    }
  }, [vo2max])

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()

    const interval = setInterval(() => {
      if (patientId) fetch()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const displayValue = vo2max ? formatNumber(vo2max) : 'N/A'
  const displayGoal = isFemale
    ? `${VO2_MAX_FEMALE_GOAL}+`
    : `${VO2_MAX_MALE_GOAL}+`
  const goalIsReached = isFemale
    ? vo2max >= VO2_MAX_FEMALE_GOAL
    : vo2max >= VO2_MAX_MALE_GOAL

  return {
    value: vo2max,
    displayValue,
    goal: isFemale ? VO2_MAX_FEMALE_GOAL : VO2_MAX_MALE_GOAL,
    displayGoal,
    goalIsReached,
    date: getDateOrTime(date),
  }
}
