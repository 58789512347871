import { useEffect, useState } from 'react'

import { useFetch } from '../../../hooks'
import { getScore } from '../../../services/api'
import { formatNumber } from '../../../utils'

const STORAGE_KEY = 'jevittyScore'

function restoreData() {
  const data = localStorage.getItem(STORAGE_KEY)

  return data ? Number(data) : null
}

function storeData(massIndex: number) {
  localStorage.setItem(STORAGE_KEY, massIndex.toString())
}

function clearData() {
  localStorage.removeItem(STORAGE_KEY)
}

export default function useJevittyScore() {
  const [score, setScore] = useState<number | null>(restoreData())

  const { isFetching, isFetched, fetch, data } = useFetch(getScore)

  useEffect(() => {
    if (data) {
      setScore(data.score)
    } else if (isFetched) {
      setScore(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (score !== null) {
      storeData(score)
    }
  }, [score])

  useEffect(() => {
    if (!isFetching && !isFetched) {
      fetch()
    }

    const interval = setInterval(() => fetch(), 15000)

    return () => clearInterval(interval)
  }, [])

  const displayScore = score ? formatNumber(score, 3) : 'N/A'

  return {
    score: score ? score : 0,
    displayScore,
    isFetching,
    isFetched,
  }
}
