import React from 'react'
import pluralize from 'pluralize'

import FormInput from 'components/FormInput'

import { useSmsConfirmation } from './hooks'

type SmsConfirmationProps = {
  setToken: (token: string) => void
  login: string
  password: string
  phoneConfirmationId: string
  maskedPhoneNumber: string
}

import './SignInForm.scss'

export default function SmsConfirmationForm({
  login,
  password,
  phoneConfirmationId,
  maskedPhoneNumber,
  setToken,
}: SmsConfirmationProps) {
  const { form, submit, handleSubmit, resend } = useSmsConfirmation({
    login,
    password,
    phoneConfirmationId,
    setToken,
  })

  const isDisabled = submit.isSubmitting || !form.formState.isValid

  return (
    <form className="sign-in-form" onSubmit={handleSubmit}>
      <p>
        We’ve sent the code to <code>{maskedPhoneNumber}</code>. <br />
        Enter the text below once you get it.
      </p>
      <FormInput
        type="text"
        name="code"
        autoComplete="off"
        ref={form.register}
        placeholder="Code from SMS"
        error={form.errors.code}
      />

      <div className="sign-in-form__footer">
        <button
          className="sign-in-form__submit btn btn-primary"
          type="submit"
          disabled={isDisabled}
        >
          Confirm
        </button>

        {resend.resendCountdown ? (
          <span>
            Send again in {pluralize('second', resend.resendCountdown, true)}
          </span>
        ) : (
          <button
            className="btn btn-sm btn-secondary"
            disabled={resend.isResending}
            onClick={resend.resend}
          >
            {resend.isResending ? 'Sending again…' : 'Send again'}
          </button>
        )}
      </div>
    </form>
  )
}
