import React from 'react'

import { useTwoFactorAuthentication } from './hooks'

import EmailAndPasswordForm from './EmailAndPasswordForm'
import SmsConfirmationForm from './SmsConfirmationForm'

export default function SignInForm() {
  const {
    setEmailAndPasswordResult,
    emailAndPasswordResult,
    setToken,
    isStageOne,
    token,
  } = useTwoFactorAuthentication()

  if (token) {
    return <>Authenticated</>
  }

  if (isStageOne) {
    return <EmailAndPasswordForm setResult={setEmailAndPasswordResult} />
  }

  return <SmsConfirmationForm {...emailAndPasswordResult} setToken={setToken} />
}
