import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

import { getAidboxResources, Observation } from 'services/api'
import { useFetch } from 'hooks'
import { STEPS_GOAL } from 'settings'
import useLocalStorage from './useLocalStorage'
import { HealthDataItemValues } from '../types'
import { getDateOrTime } from 'utils'

const STORAGE_KEY = 'jevittyStepsData'

const { storeData, restoreData, clearData } = useLocalStorage(STORAGE_KEY)

export default function useSteps(patientId: string): HealthDataItemValues {
  const [steps, setSteps] = useState<number | null>(restoreData().value)
  const [date, setDate] = useState<string | null>(restoreData().date)

  const { fetch, data, isFetching, isFetched } = useFetch(
    async () =>
      await getAidboxResources<Observation>('Observation', {
        patient: patientId,
        code: 'HKQuantityTypeIdentifierStepCount',
        '.effective.Period.start::timestamptz$ge': DateTime.local()
          .startOf('day')
          .toUTC()
          .toString(),
        _elements: 'value.Quantity.value,effective.Period.start',
      }),
  )

  useEffect(() => {
    if (data && data[0]) {
      const total = data.reduce(
        (result, { value }) => result + value.Quantity.value,
        0,
      )

      setSteps(Math.round(total))
      setDate(data[0].effective.Period.start)
    } else if (isFetched) {
      setSteps(null)
      setDate(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (steps !== null) {
      storeData(steps, date)
    }
  }, [steps])

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()

    const interval = setInterval(() => {
      if (patientId) fetch()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const displayValue = steps ? new Intl.NumberFormat().format(steps) : 'N/A'
  const displayGoal = new Intl.NumberFormat().format(STEPS_GOAL) + '+'
  const goalIsReached = steps >= STEPS_GOAL

  return {
    value: steps,
    displayValue,
    goal: STEPS_GOAL,
    displayGoal,
    goalIsReached,
    date: getDateOrTime(date),
  }
}
