import React from 'react'
import { Helmet } from 'react-helmet'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Dashboard from 'app/Dashboard'
import isBrowser from 'utils/isBrowser'

export default function DashboardApp() {
  return (
    <AuthProvider>
      <UserProvider>
        <Helmet>
          <title>Jevitty | Dashboard</title>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>

        {isBrowser() && <Dashboard />}
      </UserProvider>
    </AuthProvider>
  )
}
