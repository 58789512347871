import { useState } from 'react'

import { useAuthentication } from 'hooks'

export type EmailAndPasswordResult = {
  login: string | null
  password: string | null
  phoneConfirmationId: string | null
  maskedPhoneNumber: string | null
}

export default function useTwoFactorAuthentication() {
  const { token, login } = useAuthentication()

  const [emailAndPasswordResult, setEmailAndPasswordResult] =
    useState<EmailAndPasswordResult | null>(null)

  return {
    isStageOne: !emailAndPasswordResult,
    emailAndPasswordResult,
    setEmailAndPasswordResult,
    setToken: login,
    token,
  }
}
