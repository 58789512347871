import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

import { getAidboxResources, Observation } from 'services/api'
import { useFetch } from 'hooks'
import { HealthDataItemValues } from '../types'
import { EXERCISE_GOAL } from 'settings'
import useLocalStorage from './useLocalStorage'
import { getDateOrTime } from 'utils'

const STORAGE_KEY = 'jevittyExerciseData'

const { storeData, restoreData, clearData } = useLocalStorage(STORAGE_KEY)

export default function useExercise(patientId: string): HealthDataItemValues {
  const [exercise, setExercise] = useState<number | null>(restoreData().value)
  const [date, setDate] = useState<string | null>(restoreData().date)

  const { fetch, data, isFetching, isFetched } = useFetch(
    async () =>
      await getAidboxResources<Observation>('Observation', {
        patient: patientId,
        code: 'HKQuantityTypeIdentifierAppleExerciseTime',
        '.effective.Period.start::timestamptz$ge': DateTime.local()
          .startOf('day')
          .toUTC()
          .toString(),
        _count: 1,
        _elements: 'value.Quantity.value,effective.Period.start',
      }),
  )

  useEffect(() => {
    if (data && data[0]) {
      const total = data[0].value.Quantity.value / 60.0

      setExercise(total)
      setDate(data[0].effective.Period.start)
    } else if (isFetched) {
      setExercise(null)
      setDate(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (exercise !== null) {
      storeData(exercise, date)
    }
  }, [exercise])

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()

    const interval = setInterval(() => {
      if (patientId) fetch()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const displayValue = exercise ? `${exercise} mins` : 'N/A'
  const displayGoal = `${EXERCISE_GOAL}+ mins`
  const goalIsReached = exercise >= EXERCISE_GOAL

  return {
    value: exercise,
    displayValue,
    goal: EXERCISE_GOAL,
    displayGoal,
    goalIsReached,
    date: getDateOrTime(date),
  }
}
