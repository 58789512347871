import { useState } from 'react'

type ScoreDescriptionValues = {
  descriptionIsVisible: boolean
  toggleDescription: () => void
}

export default function useScoreDescription(): ScoreDescriptionValues {
  const [descriptionIsVisible, setDescriptionVisibility] =
    useState<boolean>(false)

  const toggleDescription = () =>
    setDescriptionVisibility(!descriptionIsVisible)

  return { descriptionIsVisible, toggleDescription }
}
