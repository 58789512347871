import { useEffect, useState } from 'react'

import { getAidboxResources, Observation } from 'services/api'
import { useFetch } from 'hooks'
import { ACTIVITY_GOAL } from 'settings'
import { HealthDataItemValues } from '../types'
import useLocalStorage from './useLocalStorage'
import { getDateOrTime } from 'utils'

const STORAGE_KEY = 'jevittyActivityData'

const { storeData, restoreData, clearData } = useLocalStorage(STORAGE_KEY)

export default function useActivity(patientId: string): HealthDataItemValues {
  const [activity, setActivity] = useState<number | null>(restoreData().value)
  const [date, setDate] = useState<string | null>(restoreData().date)

  const { fetch, data, isFetching, isFetched } = useFetch(
    async () =>
      await getAidboxResources<Observation>('Observation', {
        patient: patientId,
        code: 'HKQuantityTypeIdentifierActiveEnergyBurned',
        _sort: '-.effective.Period.end',
        _count: 1,
        _elements: 'value.Quantity.value,effective.Period.start',
      }),
  )

  useEffect(() => {
    if (data && data[0]) {
      setActivity(Math.round(data[0].value.Quantity.value))
      setDate(data[0].effective.Period.start)
    } else if (isFetched) {
      setActivity(null)
      setDate(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (activity !== null) {
      storeData(activity, date)
    }
  }, [activity])

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()

    const interval = setInterval(() => {
      if (patientId) fetch()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const displayValue = activity
    ? `${new Intl.NumberFormat().format(activity)} kcal`
    : 'N/A'
  const displayGoal = `${new Intl.NumberFormat().format(ACTIVITY_GOAL)}+ kcal`
  const goalIsReached = activity >= ACTIVITY_GOAL

  return {
    value: activity,
    displayValue,
    goal: ACTIVITY_GOAL,
    displayGoal,
    goalIsReached,
    date: getDateOrTime(date),
  }
}
