import React from 'react'
import classNames from 'classnames'

import Dial from '../Dial'
import { useJevittyScore, useScoreDescription } from './hooks'
import { JEVITTY_SCORE_GOAL } from 'settings'

import './JevittyScore.scss'

import shieldLogo from 'assets/shield-logo.svg'
import logo from 'assets/logo.svg'

const DIAMETER = 240

type JevittyScoreProps = {
  className?: string
}

export default function JevittyScore({ className }: JevittyScoreProps) {
  const { score, displayScore } = useJevittyScore()
  const { descriptionIsVisible, toggleDescription } = useScoreDescription()

  return (
    <article className={classNames('jevitty-score', className)}>
      <div className="jevitty-score__dial" style={{ maxWidth: DIAMETER }}>
        <div className="jevitty-score__dial__content">
          <img
            className="jevitty-score__shield-logo"
            src={shieldLogo}
            alt="Jevitty Shield Logo"
          />
          <h1 className="jevitty-score__value">{displayScore}</h1>
          <hr className="jevitty-score__dial__content__divider" />
          <h2 className="jevitty-score__tagline">
            <img
              className="jevitty-score__tagline__logo"
              src={logo}
              alt="Jevitty"
            />{' '}
            Score
          </h2>
        </div>

        <Dial
          className="jevitty-score__dial__svg"
          value={score}
          goal={JEVITTY_SCORE_GOAL}
          diameter={DIAMETER}
          strokeWidth={8}
        />

        <button
          className="jevitty-score__info-button"
          onClick={toggleDescription}
        >
          i
        </button>
      </div>

      {descriptionIsVisible && (
        <div className="jevitty-score__description">
          <p className="jevitty-score__description__text">
            The score is based on the personal information provided at sign up
            and current Health&nbsp;data.
          </p>
          <button
            className="jevitty-score__description__button btn btn-sm btn-secondary"
            onClick={toggleDescription}
          >
            Ok
          </button>
        </div>
      )}
    </article>
  )
}
