import React, { ReactElement } from 'react'

type DialProps = {
  className?: string
  value?: number
  goal?: number
  diameter?: number
  strokeColor?: string
  strokeWidth?: number
}

const charcoalAccent = '#363a3b'
const lightGray = '#f2f1f0'

export default function Dial({
  className,
  value = 0,
  goal = value,
  diameter = 60,
  strokeColor = charcoalAccent,
  strokeWidth = 4,
}: DialProps): ReactElement {
  const center = diameter / 2
  const radius = (diameter - strokeWidth) / 2
  const circle = Math.PI * (diameter - strokeWidth)
  const arc =
    value + goal === 0 ? 0 : value > goal ? circle : (value / goal) * circle

  return (
    <svg
      className={className}
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
    >
      <g>
        <circle
          cx={center}
          cy={center}
          r={radius - 2}
          fill="transparent"
          stroke={lightGray}
          strokeWidth={strokeWidth + 4}
        />
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="transparent"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={`${arc}, ${circle}`}
          transform={`rotate(-90, ${center}, ${center})`}
          strokeLinecap="butt"
        />
      </g>
    </svg>
  )
}
