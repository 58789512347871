import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

import { getAidboxResources, Observation } from 'services/api'
import { useFetch } from 'hooks'
import { SLEEP_GOAL_MIN, SLEEP_GOAL_MAX } from 'settings'
import useLocalStorage from './useLocalStorage'
import { HealthDataItemValues } from '../types'
import { getDateOrTime } from 'utils'

type Period = {
  start: number
  end: number
}

const STORAGE_KEY = 'jevittySleepData'

const { storeData, restoreData, clearData } = useLocalStorage(STORAGE_KEY)

export const getSleepTimeValue = (
  samples: Array<Observation>,
): { value: number; date: string } => {
  const periods = samples
    .map(item => {
      return {
        start: DateTime.fromISO(item.effective.Period.start).valueOf(),
        end: DateTime.fromISO(item.effective.Period.end).valueOf(),
      }
    })
    .sort((a: Period, b: Period) => a.start - b.start)

  const completedPeriods: Period[] = []

  for (let i = 0; i < periods.length - 1; i++) {
    const current = {
      start: periods[i].start.valueOf(),
      end: periods[i].end.valueOf(),
    }
    const next = {
      start: periods[i + 1].start.valueOf(),
      end: periods[i + 1].end.valueOf(),
    }

    if (current.start <= next.start && current.end >= next.end) {
      periods[i + 1] = { start: current.start, end: current.end }
      continue
    }

    if (next.start <= current.end && current.end <= next.end) {
      periods[i + 1] = { start: current.start, end: next.end }
      continue
    }

    if (current.end < next.start) {
      completedPeriods.push(current)
      continue
    }
  }

  if (periods && periods.length > 0) {
    completedPeriods.push(periods[periods.length - 1])

    const millis = completedPeriods
      .map(({ start, end }: Period): number => end - start)
      .reduce((acc, value) => acc + value, 0)

    const period = completedPeriods.pop()

    return {
      value: Math.round(millis / 60000),
      date: DateTime.fromMillis(period.end).toLocal().toString(),
    }
  }
}

export default function useSleep(patientId: string): HealthDataItemValues {
  const [sleep, setSleep] = useState<number | null>(restoreData().value)
  const [date, setDate] = useState<string | null>(restoreData().date)

  const { fetch, data, isFetched, isFetching } = useFetch(
    async () =>
      await getAidboxResources<Observation>('Observation', {
        patient: patientId,
        code: 'HKCategoryValueSleepAnalysis',
        '.value.CodeableConcept.coding.0.code':
          'HKCategoryValueSleepAnalysis.asleep,HKCategoryValueSleepAnalysis.inBed',
        '.effective.Period.start::timestamptz$ge': DateTime.local()
          .startOf('day')
          .minus({ hour: 12 })
          .toUTC()
          .toString(),
        _elements: 'effective.Period,value',
      }),
  )

  useEffect(() => {
    if (data && data[0]) {
      const asleep = data.filter(
        ({ value }) =>
          value.CodeableConcept.coding[0].code ===
          'HKCategoryValueSleepAnalysis.asleep',
      )

      const samples = asleep.length ? asleep : data
      const { value, date } = getSleepTimeValue(samples)

      setSleep(Math.round(value))
      setDate(date)
    } else if (isFetched) {
      setSleep(null)
      setDate(null)
      clearData()
    }
  }, [data, isFetched])

  useEffect(() => {
    if (sleep !== null) {
      storeData(sleep, date)
    }
  }, [sleep])

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()

    const interval = setInterval(() => {
      if (patientId) fetch()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const hours = sleep ? Math.floor(sleep / 60) : 0
  const minutes = sleep ? sleep % 60 : 0
  const sleepGoalMinHrs = Math.floor(SLEEP_GOAL_MIN / 60)
  const sleepGoalMaxHrs = Math.floor(SLEEP_GOAL_MAX / 60)
  const displayValue = sleep ? `${hours} hrs ${minutes} mins` : 'N/A'
  const goalIsReached = sleep >= SLEEP_GOAL_MIN && sleep <= SLEEP_GOAL_MAX

  return {
    value: sleep,
    displayValue,
    goal: SLEEP_GOAL_MIN,
    displayGoal: `${sleepGoalMinHrs}-${sleepGoalMaxHrs} hrs`,
    goalIsReached,
    date: getDateOrTime(date),
  }
}
