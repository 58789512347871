import React from 'react'

import Dial from '../Dial'
import { HealthDataItem } from './types'

import './Tile.scss'

export default function Tile(props: HealthDataItem) {
  const {
    title,
    icon,
    value,
    goal,
    displayValue,
    displayGoal,
    goalIsReached,
    date,
  } = props

  const green = '#5ec07e'
  const gold = '#FFB800'
  const strokeColor = goalIsReached ? green : gold

  return (
    <div className="col-12 col-sm-6 col-xl-4">
      <article className="dashboard__health-data-tile">
        <div className="dashboard__health-data-tile__graph">
          <img
            className="dashboard__health-data-tile__graph__icon"
            src={icon}
            alt={`${title} icon`}
          />
          <Dial value={value} goal={goal} strokeColor={strokeColor} />
        </div>
        <div className="dashboard__health-data-tile__text">
          <div className="dashboard__health-data-tile__meta">
            <h2 className="dashboard__health-data-tile__name">{title}</h2>
            {date && (
              <p className="dashboard__health-data-tile__date">{date}</p>
            )}
          </div>
          <p className="dashboard__health-data-tile__value">
            {displayValue || value}
          </p>
          {displayGoal && (
            <p className="dashboard__health-data-tile__goal">{displayGoal}</p>
          )}
        </div>
      </article>
    </div>
  )
}
