import {
  useSleep,
  useSteps,
  useActivity,
  useExercise,
  useWeight,
  useBodyMassIndex,
  useBodyFat,
  useVo2Max,
  useRestingHeartRate,
} from '../hooks'
import { HealthDataItem } from '../types'

import sleepIcon from '../assets/sleep.svg'
import stepsIcon from '../assets/steps.svg'
import activityIcon from '../assets/activity.svg'
import exerciseIcon from '../assets/exercise.svg'
import weightIcon from '../assets/weight.svg'
import bmiIcon from '../assets/bmi.svg'
import bodyFatIcon from '../assets/body-fat.svg'
import vo2MaxIcon from '../assets/vo2-max.svg'
import restingHeartRateIcon from '../assets/resting-heart-rate.svg'
import { useUser, useGender } from 'hooks'

export default function useHealthData(): HealthDataItem[] {
  const {
    data: { patientId },
  } = useUser()
  const { gender } = useGender(patientId)

  const sleep = useSleep(patientId)
  const steps = useSteps(patientId)
  const activity = useActivity(patientId)
  const exercise = useExercise(patientId)
  const weight = useWeight(patientId)
  const bmi = useBodyMassIndex(patientId)
  const bodyFat = useBodyFat(patientId, gender)
  const vo2Max = useVo2Max(patientId, gender)
  const restingHeartRate = useRestingHeartRate(patientId)

  return [
    {
      title: 'Sleep',
      icon: sleepIcon,
      ...sleep,
    },
    {
      title: 'Steps',
      icon: stepsIcon,
      ...steps,
    },
    {
      title: 'Activity',
      icon: activityIcon,
      ...activity,
    },
    {
      title: 'Exercise',
      icon: exerciseIcon,
      ...exercise,
    },
    {
      title: 'Weight',
      icon: weightIcon,
      ...weight,
    },
    {
      title: 'BMI',
      icon: bmiIcon,
      ...bmi,
    },
    {
      title: 'Body Fat',
      icon: bodyFatIcon,
      ...bodyFat,
    },
    {
      title: 'Vo2 Max',
      icon: vo2MaxIcon,
      ...vo2Max,
    },
    {
      title: 'Resting Heart Rate',
      icon: restingHeartRateIcon,
      ...restingHeartRate,
    },
  ]
}
