import React from 'react'

import Tile from './Tile'
import { useHealthData } from './hooks'

type HealthDataProps = {
  className?: string
}

export default function HealthData({ className }: HealthDataProps) {
  const healthData = useHealthData()

  return (
    <div className={className}>
      {healthData.map((item, index) => (
        <Tile {...item} key={index} />
      ))}
    </div>
  )
}
