import React from 'react'
import { Link } from 'gatsby'

import FormInput from 'components/FormInput'

import { useEmailAndPasswordSignIn } from './hooks'
import { EmailAndPasswordResult } from './hooks/useTwoFactorAuthentication'

type EmailAndPasswordFormProps = {
  setResult: (result: EmailAndPasswordResult) => void
}

import './SignInForm.scss'

export default function EmailAndPasswordForm({
  setResult,
}: EmailAndPasswordFormProps) {
  const { form, submit, handleSubmit } = useEmailAndPasswordSignIn(setResult)

  return (
    <form className="sign-in-form" onSubmit={handleSubmit}>
      <fieldset disabled={submit.isSubmitting}>
        <FormInput
          type="email"
          name="login"
          autoComplete="username"
          placeholder="Email"
          ref={form.register}
          isDirty={form.formState.dirtyFields.login}
          error={form.errors.login}
        />
        <FormInput
          type="password"
          name="password"
          autoComplete="current-password"
          placeholder="Password"
          ref={form.register}
          isDirty={form.formState.dirtyFields.password}
          error={form.errors.password}
        />

        <div className="sign-in-form__footer">
          <button
            className="sign-in-form__submit btn btn-primary"
            type="submit"
            disabled={submit.isSubmitting || !form.formState.isValid}
          >
            Sign In
          </button>
          <Link className="sign-in-form__forgot-password" to="/reset-password/">
            Forgot password?
          </Link>
        </div>
      </fieldset>
    </form>
  )
}
