export default function useLocalStorage(storageKey: string) {
  function restoreData() {
    const storageString: string | null = localStorage.getItem(storageKey)
    const storageObject = storageString && JSON.parse(storageString)

    return storageObject
      ? storageObject
      : {
          value: null,
          date: null,
        }
  }

  function storeData(value: number, date: string) {
    localStorage.setItem(storageKey, JSON.stringify({ value, date }))
  }

  function clearData() {
    localStorage.removeItem(storageKey)
  }

  return { storeData, restoreData, clearData }
}
