import React from 'react'

import Layout from 'components/Layout'
import JevittyScore from 'components/JevittyScore'
import HealthData from 'components/HealthData'
import SignInForm from 'components/SingInForm'
import Preloader from 'components/Preloader'

import { useUserLoading } from 'hooks'

import './Dashboard.scss'

export default function Dashboard() {
  const { isAuthenticated, isLoaded } = useUserLoading()

  if (isAuthenticated) {
    if (isLoaded) {
      return (
        <Layout className="dashboard-page">
          <div className="dashboard row">
            <div className="col-lg-4 col-xl-3">
              <JevittyScore className="dashboard__score" />
            </div>
            <div className="col-lg-8 col-xl-9">
              <HealthData className="row" />
            </div>
          </div>
        </Layout>
      )
    }

    return <Preloader />
  }

  return (
    <Layout className="sign-in-page">
      <div className="row justify-content-center">
        <div className="col-md-6 col-xl-4">
          <SignInForm />
        </div>
      </div>
    </Layout>
  )
}
